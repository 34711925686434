import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`23rd October 2023`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Context Path Support`}</strong>{`: You can now set services/decorators under a context path fluently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3591"
          }}>{`#3591`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4802"
          }}>{`#4802`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5037"
          }}>{`#5037`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5157"
          }}>{`#5157`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5260"
          }}>{`#5260`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .baseContextPath("/api")
      .contextPath("/v1", "/v2")
      .service(myService) // served under "/api/v1" and "/api/v2"
      .and()
      .virtualHost("foo.com")
      .contextPath("/v3")
      .service(myService) // served by virtual host "foo.com" under "/api/v3"
      .build();
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`You can also set the context path via Spring property.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`armeria:
  context-path: /api/v1
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Easier Request Scoping for Kotlin`}</strong>{`: You can now easily propagate an Armeria `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` in Kotlin using
`}<a parentName="p" {...{
            "href": "type://ContextAwareExecutor#asCoroutineDispatcher()"
          }}>{`type://ContextAwareExecutor#asCoroutineDispatcher()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5171"
          }}>{`#5171`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`withContext(ctx.eventLoop().asCoroutineDispatcher()) {
  assert(RequestContext.current() == ctx)
  ...
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`gRPC Exception Handler Annotation`}</strong>{`: You can now easily handle an exception that is raised from a gRPC service using
`}<a parentName="p" {...{
            "href": "type://@GrpcExceptionHandler:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcExceptionHandler.html"
          }}>{`type://@GrpcExceptionHandler`}</a>{` annotation. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5046"
          }}>{`#5046`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@GrpcExceptionHandler(MyGrpcExceptionHandlerFunction.class)
@Override
public void unaryCall(
  SimpleRequest request,
  StreamObserver<SimpleResponse> responseObserver) {
    ...
}

class MyGrpcExceptionHandlerFunction implements GrpcExceptionHandlerFunction {
  @Override
  public Status apply(RequestContext ctx, Throwable cause, Metadata metadata) {
    if (unauthenticatedException(cause)) {
      return Status.UNAUTHENTICATED;
    }
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`StreamMessage finalizer`}</strong>{`: You can now produce the last value for a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` when it completes
exceptionally or successfully using `}<a parentName="p" {...{
            "href": "type://StreamMessage#endWith(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#endWith(java.util.function.Function)"
          }}>{`type://StreamMessage#endWith(Function)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4816"
          }}>{`#4816`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5198"
          }}>{`#5198`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage<Integer> message =
  original.endWith(th -> {
      if (th instanceof AbortedStreamException) {
          return 100;
      }
      return -1;
  });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Disable HTTP/2 for Requests`}</strong>{`: You can now disable HTTP/2 protocol when sending HTTP or HTTPS requests. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5168"
          }}>{`#5168`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory
  .builder()
  .preferHttp1(true)
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Client Certificate Metrics`}</strong>{`: You can now monitor client certificates with the following metrics. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5155"
          }}>{`#5155`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.tls.certificates.validity`}</inlineCode>{`: `}<inlineCode parentName="li">{`1`}</inlineCode>{` if valid, `}<inlineCode parentName="li">{`0`}</inlineCode>{` otherwise.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.client.tls.certificates.validity.days`}</inlineCode>{`: The number of days until the certificate expires.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now add a hook that is invoked when a `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` is pushed and popped. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4991"
          }}>{`#4991`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5107"
          }}>{`#5107`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Supplier<AutoCloseable> contextHook = () -> {
  System.out.println("Context is pushed.");
  return () -> {
    System.out.println("Context is popped.");
  };
}

// Client
WebClient
  .builder()
  .contextHook(contextHook)
  ...
  .build();
// Server
Server
  .builder()
  .contextHook(contextHook)
  ...
  .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`New Circuit Breaker and Retry Rule for Time-consuming Requests`}</strong>{`: You can now report a failure to a
circuit breaking or retry when the total duration exceeds a certain threshold. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4782"
          }}>{`#4782`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4827"
          }}>{`#4827`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`final CircuitBreakerRuleWithContent<HttpResponse> rule =
      CircuitBreakerRuleWithContent
              .<HttpResponse>builder()
              .onTotalDuration(
                  (ctx, duration) -> duration.toMillis() > 10000)
              .thenFailure();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RST Flood Attack Prevention`}</strong>{`: You can now protect your server against
`}<a parentName="p" {...{
            "href": "https://www.securityweek.com/rapid-reset-zero-day-exploited-to-launch-largest-ddos-attacks-in-history/"
          }}>{`DDoS caused by RST flood attack`}</a>{`
using `}<a parentName="p" {...{
            "href": "type://ServerBuilder#http2MaxResetFramesPerWindow(int,int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#http2MaxResetFramesPerWindow(int,int)"
          }}>{`type://ServerBuilder#http2MaxResetFramesPerWindow(int,int)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5232"
          }}>{`#5232`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`The default maximum number of allowed RST frames for an HTTP/2 connection is 400 per minute.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .http2MaxResetFramesPerWindow(100, 10)
  .build();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Acquiring an `}<inlineCode parentName="li">{`EventLoop`}</inlineCode>{` for sending a request is now improved when the number of `}<inlineCode parentName="li">{`EventLoop`}</inlineCode>{`s is small.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4439"
        }}>{`#4439`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4682"
        }}>{`#4682`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/google/snappy"
        }}>{`Snappy`}</a>{` is now supported for content compression. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5125"
        }}>{`#5125`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5174"
        }}>{`#5174`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{`s of a `}<a parentName="li" {...{
          "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
        }}>{`type://RequestLog`}</a>{` are now completed with the
`}<a parentName="li" {...{
          "href": "type://RequestContext#eventLoop():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#eventLoop()"
        }}>{`type://RequestContext#eventLoop()`}</a>{` thus their callbacks are also executed by the `}<inlineCode parentName="li">{`EventLoop`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5189"
        }}>{`#5189`}</a></li>
      <li parentName="ul">{`Adding services and decorators are now unified. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5038"
        }}>{`#5038`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5040"
        }}>{`#5040`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestLog#responseCause():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html#responseCause()"
        }}>{`type://RequestLog#responseCause()`}</a>{` is correctly propagated to `}<a parentName="li" {...{
          "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
        }}>{`type://CircuitBreakerRule`}</a>{` when using
a `}<a parentName="li" {...{
          "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
        }}>{`type://CircuitBreakerClient`}</a>{` with a `}<a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5242"
        }}>{`#5242`}</a></li>
      <li parentName="ul">{`A WebSocket session is correctly established even if the `}<inlineCode parentName="li">{`Connection`}</inlineCode>{` header has `}<inlineCode parentName="li">{`upgrade`}</inlineCode>{` value that is
concateneted with other values using a comma. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5165"
        }}>{`#5165`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5230"
        }}>{`#5230`}</a></li>
      <li parentName="ul">{`No more leaks when the size of a request exceeds the max content length. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3803"
        }}>{`#3803`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5227"
        }}>{`#5227`}</a></li>
      <li parentName="ul">{`An `}<inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` is raised if `}<a parentName="li" {...{
          "href": "type://GrpcSerializationFormats#JSON:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcSerializationFormats.html#JSON"
        }}>{`type://GrpcSerializationFormats#JSON`}</a>{` isn't set when
`}<a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#enableHttpJsonTranscoding(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#enableHttpJsonTranscoding(boolean)"
        }}>{`type://GrpcServiceBuilder#enableHttpJsonTranscoding(boolean)`}</a>{` is enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5176"
        }}>{`#5176`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5224"
        }}>{`#5224`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://CookieClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/cookie/CookieClient.html"
        }}>{`type://CookieClient`}</a>{` is now correctly working with redirects. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5221"
        }}>{`#5221`}</a></li>
      <li parentName="ul">{`You no longer see duplicate `}<a parentName="li" {...{
          "href": "type://ClientOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientOptions.html"
        }}>{`type://ClientOptions`}</a>{` when creating a derived client via
`}<a parentName="li" {...{
          "href": "type://Clients#newDerivedClient(T,Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Clients.html#newDerivedClient(T,java.util.function.Function)"
        }}>{`type://Clients#newDerivedClient(T,Function)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5194"
        }}>{`#5194`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`UnsupportedOperationException`}</inlineCode>{` when creating an `}<a parentName="li" {...{
          "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
        }}>{`type://HttpResponse`}</a>{` with
a `}<inlineCode parentName="li">{`CompletationStage`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5190"
        }}>{`#5190`}</a></li>
      <li parentName="ul">{`A new HTTP/2 connection is created and correctly reused from the pending requests when the number of requests
exceeds max concurrent streams. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5173"
        }}>{`#5173`}</a></li>
      <li parentName="ul">{`You no longer see a false negative `}<a parentName="li" {...{
          "href": "type://EndpointSelectionTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionTimeoutException.html"
        }}>{`type://EndpointSelectionTimeoutException`}</a>{` when
`}<a parentName="li" {...{
          "href": "type://EndpointSelectionStrategy#weightedRoundRobin():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html#weightedRoundRobin()"
        }}>{`type://EndpointSelectionStrategy#weightedRoundRobin()`}</a>{` is used with a `}<a parentName="li" {...{
          "href": "type://DynamicEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/DynamicEndpointGroup.html"
        }}>{`type://DynamicEndpointGroup`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5163"
        }}>{`#5163`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` isn't raised anymore when a client establishes an HTTP session. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5162"
        }}>{`#5162`}</a></li>
      <li parentName="ul">{`Pending exceptions that are raised while sending requests or receiving responses are now correctly
propagated to users. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5154"
        }}>{`#5154`}</a></li>
      <li parentName="ul">{`Armeria gRPC service no longer schedules a timeout if `}<inlineCode parentName="li">{`grpc-timeout`}</inlineCode>{` header is omitted when
`}<a parentName="li" {...{
          "href": "type://GrpcServiceBuilder#useClientTimeoutHeader(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#useClientTimeoutHeader(boolean)"
        }}>{`type://GrpcServiceBuilder#useClientTimeoutHeader(boolean)`}</a>{` is enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5144"
        }}>{`#5144`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Content-Length`}</inlineCode>{` header isn't set anymore for the streaming response of a HEAD request. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4509"
        }}>{`#4509`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4644"
        }}>{`#4644`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcStatusFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcStatusFunction.html"
        }}>{`type://GrpcStatusFunction`}</a>{` is deprecated. Use `}<a parentName="li" {...{
          "href": "type://GrpcExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcExceptionHandlerFunction.html"
        }}>{`type://GrpcExceptionHandlerFunction`}</a>{` instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5046"
        }}>{`#5046`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5267"
        }}>{`#5267`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`exceptionMapping`}</inlineCode>{` methods on `}<a parentName="li" {...{
              "href": "type://GrpcServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html"
            }}>{`type://GrpcServiceBuilder`}</a>{` are deprecated.
Use `}<a parentName="li" {...{
              "href": "type://GrpcExceptionHandlerFunctionBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcExceptionHandlerFunctionBuilder.html"
            }}>{`type://GrpcExceptionHandlerFunctionBuilder`}</a>{` instead.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://GoogleGrpcStatusFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GoogleGrpcStatusFunction.html"
            }}>{`type://GoogleGrpcStatusFunction`}</a>{` is also deprecated. Use `}<a parentName="li" {...{
              "href": "type://GoogleGrpcExceptionHandlerFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GoogleGrpcExceptionHandlerFunction.html"
            }}>{`type://GoogleGrpcExceptionHandlerFunction`}</a>{`
instead.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`N/A`}</li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Dropwizard2 2.1.6 → 2.1.8`}</li>
      <li parentName="ul">{`Dropwizard metrics 4.2.19 → 4.2.21`}</li>
      <li parentName="ul">{`Futures-extra 4.3.1 → 4.3.3`}</li>
      <li parentName="ul">{`Graphql Kotlin 6.5.3 → 6.5.6`}</li>
      <li parentName="ul">{`gRPC 1.57.2 → 1.58.0`}</li>
      <li parentName="ul">{`gRPC Kotlin 1.3.0 → 1.4.0`}</li>
      <li parentName="ul">{`Guava 32.1.2-jre → 32.1.3-jre`}</li>
      <li parentName="ul">{`Jackson 2.15.2 → 2.15.3`}</li>
      <li parentName="ul">{`Jetty`}
        <ul parentName="li">
          <li parentName="ul">{`10.0.15 → 10.0.17`}</li>
          <li parentName="ul">{`11.0.15 → 11.0.17`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Kafka 3.5.1 → 3.6.0`}</li>
      <li parentName="ul">{`Micrometer 1.11.3 → 1.11.5`}</li>
      <li parentName="ul">{`Micrometer Tracing 1.1.4 → 1.1.6`}</li>
      <li parentName="ul">{`Netty 4.1.96.Final → 4.1.100.Final`}</li>
      <li parentName="ul">{`Netty io_uring 0.0.21.Final → 0.0.23.Final`}</li>
      <li parentName="ul">{`Reactor 3.5.8 → 3.5.11`}</li>
      <li parentName="ul">{`RxJava3 3.1.6 → 3.1.8`}</li>
      <li parentName="ul">{`Sangria 4.0.1 → 4.0.2`}</li>
      <li parentName="ul">{`Scala2.13 2.13.11 → 2.13.12`}</li>
      <li parentName="ul">{`Spring Framework 6.0.11 → 6.0.13`}</li>
      <li parentName="ul">{`Spring Boot`}
        <ul parentName="li">
          <li parentName="ul">{`2.7.14 → 2.7.16`}</li>
          <li parentName="ul">{`3.1.2 → 3.1.4`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Tomcat`}
        <ul parentName="li">
          <li parentName="ul">{`8.5.85 → 8.5.94`}</li>
          <li parentName="ul">{`9.0.71 → 9.0.82`}</li>
          <li parentName="ul">{`10.1.12 → 10.1.15`}</li>
        </ul>
      </li>
      <li parentName="ul">{`ZooKeeper 3.8.2 → 3.9.1`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'babjo', 'bkkanq', 'Dogacel', 'esperar', 'ghkim3221', 'ikhoon', 'injae-kim', 'jrhee17', 'kezhenxu94', 'Kyurenpoto', 'minwoox', 'my4-dev', 'ribafish', 'runningcode', 'russell-yoo', 'seonwoo960000', 'sfc-gh-skabanov', 'sh-cho', 'sindhunaydu', 'ta7uw', 'thomasbruggink', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      